<template>
  <custom-misc>
    <span class="icon-page-misc mb-2">
      <LockIcon />
    </span>

    <div class="text-center mb-1">
      <h2 class="text-center">Acesso negado</h2>
      <p class="text-center">
        Você não tem permissão para acessar a plataforma.
        Para liberar ou verificar seu acesso entre em contato com o suporte.
      </p>
    </div>

    <button
        @click="handleRedirectToLogin"
        type="button"
        class="btn btn-misc w-100"
    >
      Voltar para o login
    </button>
  </custom-misc>
</template>

<script>
import { LockIcon } from 'vue-feather-icons'
import CustomMisc from '@/views/components/custom/miscellaneous/CustomMisc'
import {clearStore} from "@/auth/utils";

export default {
  title: 'Não habilitado',

  components: {
    CustomMisc,
    LockIcon,
  },

  methods: {
    handleRedirectToLogin() {
      clearStore()
      this.$router.replace({ name: 'auth-login' });
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>

